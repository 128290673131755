export class TableBookingModel{
    _id:string = "";
    is_table_reservation:boolean = false;
    is_table_reservation_with_order:boolean = false;
    is_cancellation_charges_for_with_order:boolean = false;
    is_cancellation_charges_for_without_order:boolean = false;
    is_set_booking_fees:boolean = false;
    table_reservation_time:number = 60;
    user_come_before_time:number = 15;  
    booking_fees:number = 0;
    reservation_max_days:number = 7;
    reservation_person_min_seat:number = 2;
    reservation_person_max_seat:number = 8;
    with_order_cancellation_charges:Array<{ hours: number,type : number,value : number}> = [];
    without_order_cancellation_charges:Array<{ hours: number,type : number,value : number}> = [];
    booking_time:Array<{
      day: number,
      day_time: Array<any>,
      is_booking_open: boolean
      is_booking_open_full_time: boolean
    }> = [];
  }


  export class Table{
    created_at: Date = new Date();
    is_bussiness: boolean = false;
    is_user_can_book: boolean = false;
    store_id: string = "";
    table_code: string = "";
    table_max_person: number = 0;
    table_min_person: number = 0;
    unique_id: number = 0;
    table_no: number = 0;
    table_qrcode: string = "";
    _id: string = "";
  }